import React from "react";
import { IBtnProps } from "../../utils/types";
import { ClipLoader } from "react-spinners";

const MainBtn: React.FC<IBtnProps> = ({
  bg,
  height,
  width,
  color,
  onClick,
  loading,
  text,
}) => {
  return (
    <div>
      <button
        disabled={loading}
        onClick={onClick}
        style={{
          backgroundColor: bg || "#1D3557",
          color: color || "white",
        }}
        className={`rounded-lg disabled:cursor-not-allowed disabled:opacity-50 text-base font-medium ${
          height || "h-10"
        } ${width || "px-6"}`}
      >
        {loading ? <ClipLoader size={15} color="white" /> : `${text}`}
      </button>
    </div>
  );
};

export default MainBtn;
