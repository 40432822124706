import React, { useEffect, useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import MainHeader from "../../components/app/mainheader";
import MainBtn from "../../components/buttons/main";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import { ClipLoader } from "react-spinners";
import {
  fetchLiability,
  liabilityStat,
  useGetFunc,
} from "../../data/apis/functions/main";
import LiabilitiesTable from "../../components/tables/liabilitiestable";
import { useQuery } from "react-query";
import MainPaginate from "../../components/app/mainpaginate";
import useCategoryStore from "../../data/stores/categoriesStore";
import { CustomSelect } from "../../components/inputs/main";

const Liabilities = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const { liabilityCategory } = useCategoryStore();
  const [category, setCategory] = useState("");
  const { data, isLoading, isError } = useQuery(
    ["liabilities", pageIndex],
    () => fetchLiability(pageIndex, category),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setMeta({
          per_page: data.data?.meta.per_page,
          total: data.data?.meta.total,
        });
      },
    }
  );
  useEffect(() => {
    if (liabilityCategory) {
      const selectOptions = liabilityCategory.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(selectOptions);
    }
  }, [liabilityCategory]);
  const { data: stat, isLoading: statLoading } = useGetFunc(
    liabilityStat,
    "liability-stat"
  );

  const [meta, setMeta] = useState({
    per_page: 1,
    total: 1,
  });
  const handlePageClick = (event: any) => {
    const currentPage = event.selected + 1;
    setPageIndex(currentPage);
  };

  const Arr = [
    {
      title: "Total Liabilities",

      value: stat?.data?.result?.data?.count,
      bg: "#007AFF",
      icon: "mdi:property-tag",
    },
    {
      title: "Total value",

      value: stat?.data?.result?.data?.total_value,
      bg: "#FF6347",
      icon: "mdi:property-tag",
    },
  ];
  return (
    <div>
      <MainContainer>
        <MainHeader />
        <div className="flex justify-end mt-6">
          <MainBtn
            text="Add Liability"
            loading={false}
            onClick={() => navigate("/liabilities/add-liability")}
          />
        </div>
        <div className="mt-8 grid lg:grid-cols-2 md:grid-cols-2 gap-8 w-full">
          {Arr?.map((it) => (
            <div className="h-24 rounded-xl bg-white shadow-sm flex justify-between items-center px-5">
              <div className="size-12 rounded-full shadow-md flex justify-center items-center">
                <Icon
                  color={it?.bg}
                  style={{
                    fontSize: "24px",
                  }}
                  icon={it?.icon}
                />
              </div>
              <div>
                <h5 className="text-xs text-right font-medium text-ji-blue-100 uppercase">
                  {it?.title}
                </h5>
                <h1 className="text-2xl mt-2 text-right font-bold text-ji-blue-100">
                  {statLoading ? (
                    <ClipLoader size={18} color="#354367" />
                  ) : (
                    it?.value
                  )}
                </h1>
              </div>
            </div>
          ))}
        </div>
        <div className="flex mt-10 justify-between items-center">
          <div className="w-1/3">
            <CustomSelect
              label={"Filter"}
              options={categories}
              placeholder={"select a category"}
              onChange={(e) => setCategory(e?.value)}
            />
          </div>
          <MainBtn
            text="Reset"
            loading={false}
            onClick={() => setCategory("")}
          />
        </div>
        <div className="mt-4">
          <LiabilitiesTable
            error={isError}
            data={data?.data?.data}
            isLoading={isLoading}
          />
          <MainPaginate handlePageClick={handlePageClick} meta={meta} />
        </div>
      </MainContainer>
    </div>
  );
};

export default Liabilities;
