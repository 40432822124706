import React, { useState } from "react";
import useAuthStore from "../../data/stores/authstore";
import { AiOutlineMenu } from "react-icons/ai";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router";

const Sidebar = ({ children }) => {
  const { isAuth } = useAuthStore(),
    [nav, setNav] = useState(false),
    toggleNav = () => {
      setNav(!nav);
    },
    location = useLocation(),
    path = location?.pathname,
    navigate = useNavigate(),
    sidebarList = [
      {
        name: "dashboard",
        url: "/dashboard",
        icon: "ic:twotone-dashboard",
      },
      {
        name: "assets",
        url: "/assets",
        icon: "carbon:asset-confirm",
      },
      {
        name: "liabilities",
        url: "/liabilities",
        icon: "arcticons:debtcalc",
      },
      {
        name: "profile",
        url: "/profile",
        icon: "gg:profile",
      },
    ];
  return (
    <div>
      {isAuth && (
        <>
          <button
            onClick={toggleNav}
            className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:text-green-200 focus:outline-none overflow-visible "
          >
            <span className="sr-only">Open sidebar</span>
            <AiOutlineMenu size={20} />
          </button>
          {/* Backdrop for mobile sidebar */}
          {nav && (
            <div
              className={`fixed lg:hidden top-0 left-0 z-40 w-full h-screen bg-black opacity-50`}
              onClick={toggleNav}
            ></div>
          )}
          {/* Sidebar */}
          <aside
            className={`fixed shadow-sm top-0 left-0 z-50 w-56 bg-white rounded-r-3xl h-screen pb-8 transition-transform transform ${
              nav ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
            }`}
          >
            <div className="min-h-screen relative py-6 noScroll">
              <div className="flex justify-center my-6 w-full">
                <Icon
                  color={"#1D3557"}
                  style={{
                    fontSize: "36px",
                  }}
                  icon={"catppuccin:godot-assets"}
                />
              </div>
              <div className="mt-6 space-y-4 w-5/6 mx-auto">
                {sidebarList?.map((it) => (
                  <div
                    key={it?.url}
                    onClick={() => navigate(it?.url)}
                    className={`flex cursor-pointer gap-2 items-center h-10 px-4 w-full rounded-lg ${
                      path === it?.url || path?.includes(it?.url)
                        ? "bg-ji-blue-100 text-white"
                        : "bg-white border border-ji-blue-100 text-ji-blue-100"
                    }`}
                  >
                    <div>
                      <Icon
                        color={
                          path === it?.url || path?.includes(it?.url)
                            ? "white"
                            : "#1D3557"
                        }
                        style={{
                          fontSize: "24px",
                        }}
                        icon={it?.icon}
                      />
                    </div>
                    <strong className="text-base capitalize font-medium">
                      {it?.name}
                    </strong>
                  </div>
                ))}
              </div>
            </div>
          </aside>
        </>
      )}

      {/* Main content area */}
      <div className={`${isAuth ? "lg:ml-[224px]" : ""}`}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
