import axios from "axios";

export const TOKEN = "AUTH_TOKEN"

export const SetAuthToken = (token?: string | null) => {
	if (token) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
};


export const useURL = process.env.REACT_APP_BASE_URL

export const SetDefaultHeaders = () => {
	axios.defaults.baseURL = useURL;
};
