import { createContext, PropsWithChildren } from "react";
import { useState } from "react";

export const GlobalState = createContext({});

const DataProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [nav, setNav] = useState(false);
	const handleCapitalize = (word: string) => {
		const splitter = word.trim().split(" ");
		const firstCap = splitter[0].split("");
		const remaining = splitter.slice(1, splitter.length).join(" ");

		const firstCapOne = firstCap[0].toUpperCase();
		const firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		const joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	const numberWithCommas = (x: string) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const nairaSignNeutral = "₦";
	const nairaSign = <span className="fontInherit">&#8358;</span>;

	const toggleNav = () => {
		setNav(!nav);
	};

	const state = {
		handleCapitalize,

		numberWithCommas,

		nav,
		toggleNav,
		nairaSignNeutral,
		nairaSign,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
