import React from "react";
import MainContainer from "../components/app/maincontainer";
import MainHeader from "../components/app/mainheader";
import useAuthStore from "../data/stores/authstore";
import Avatar from "../assets/icons/avatar.svg";
import { Icon } from "@iconify/react";
import {
  fetchDashboardData,
  getQuotes,
  useGetFunc,
} from "../data/apis/functions/main";
import { useNavigate } from "react-router";
import { Spinner } from "@chakra-ui/react";
import ApexChart from "../components/app/chart";

const Dashboard = () => {
  const { data, isLoading } = useGetFunc(fetchDashboardData, "dashboard-data");
  console.log(data);
  const { user } = useAuthStore(),
    navigate = useNavigate();
  const Arr = [
    {
      title: "Total Assets",
      value: `${data?.data?.result?.data?.total_asset?.value}`,
      bg: "#007AFF",
      icon: "mdi:property-tag",
    },
    {
      title: "Total Liabilities",
      value: `${data?.data?.result?.data?.total_liability?.value}`,
      bg: "#FF6347",
      icon: "fa6-solid:money-bill-trend-up",
    },
    {
      title: "Net Worth",
      value: data?.data?.result?.data?.net_worth,
      bg: "#4CAF50",
      icon: "majesticons:money-plus",
    },
    // {
    //   title: "Net worth (NGN)",
    //   value: data?.data?.result?.data?.in_naira,
    //   bg: "#ffff00",
    //   icon: "fluent:money-24-regular",
    // },
  ];
  const { data: quotes } = useGetFunc(getQuotes, "quotes");

  return (
    <MainContainer>
      <div className="">
        <MainHeader />
        <div className="mt-8 flex lg:flex-row md:flex-col gap-10">
          <div className="lg:w-[70%] relative h-80 p-10 rounded-2xl banner">
            <h2 className="text-base uppercase font-bold text-white">
              welcome to jivanna
            </h2>
            <h4 className="text-lg font-bold text-white capitalize max-w-3xl mt-8">
              <q className="italic">{quotes?.data[0]?.quote}</q>
            </h4>
            <div className="absolute bottom-5 left-10">
              <span className="text-white font-bold text-sm">
                ~{quotes?.data[0]?.author}~
              </span>
            </div>
          </div>
          <div className="lg:w-[30%] rounded-xl p-6 bg-white shadow-lg">
            <div className="flex w-full justify-center h-full items-center">
              <div>
                <div className="p-4 border-2 border-ji-blue-100 rounded-full">
                  <img
                    src={Avatar}
                    alt=""
                    className="mx-auto h-20 border borderji-"
                  />
                </div>
                <h4 className="text-base mt-2 font-bold text-center text-ji-blue-100">{`${user?.first_name} ${user?.last_name}`}</h4>
                <div className="mt-2 flex gap-1 items-center">
                  <span className="text-base text-center first-letter:uppercase font-bold text-ji-blue-100">
                    {user?.email}
                  </span>
                  <Icon
                    color={
                      user?.email_verified_at !== null ? "#008000" : "#1D3557"
                    }
                    style={{
                      fontSize: "20px",
                    }}
                    icon={"bitcoin-icons:verify-filled"}
                  />
                </div>
                <div className="mt-2 flex gap-1 items-center">
                  <span className="text-base text-center first-letter:uppercase font-bold text-ji-blue-100">
                    +234{user?.phone_number}
                  </span>
                  <Icon
                    color={
                      user?.phone_verified_at !== null ? "#008000" : "#1D3557"
                    }
                    style={{
                      fontSize: "20px",
                    }}
                    icon={
                      user?.phone_verified_at !== null
                        ? "bitcoin-icons:verify-filled"
                        : "mdi:account-pending-outline"
                    }
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    onClick={() => navigate("/profile")}
                    className="px-4 border-ji-blue-100 border rounded-xl h-10 text-sm font-bold text-ji-blue-100"
                  >
                    View profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 grid lg:grid-cols-4 md:grid-cols-2 gap-8 w-full">
          {Arr?.map((it) => (
            <div className="h-24 rounded-xl bg-white shadow-sm flex justify-between items-center px-5">
              <div className="size-12 rounded-full shadow-md flex justify-center items-center">
                <Icon
                  color={it?.bg}
                  style={{
                    fontSize: "24px",
                  }}
                  icon={it?.icon}
                />
              </div>
              <div>
                <h5 className="text-xs text-right font-medium text-ji-blue-100 uppercase">
                  {it?.title}
                </h5>
                <h1
                  className={`text-sm mt-2 text-right font-bold ${
                    parseFloat(
                      it?.value && it?.value.replace(/[^0-9.-]+/g, "")
                    ) < 0
                      ? "text-red-500 animate-pulse"
                      : "text-ji-blue-100"
                  }`}
                >
                  {isLoading ? <Spinner size={"sm"} /> : it?.value}
                </h1>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-12 w-full">
          <ApexChart />
        </div>
      </div>
    </MainContainer>
  );
};

export default Dashboard;
