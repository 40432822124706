import React, { useState } from "react";
import { NewTextInput } from "../components/inputs/main.jsx";
import MainBtn from "../components/buttons/main";
import { ILoginData } from "../utils/types";
import { useLogin } from "../data/apis/mutations/main";
import storage from "../utils/storage";
import { useNavigate } from "react-router";
import useAuthStore from "../data/stores/authstore";
import VerifyModal from "../components/modals/verifymodal";
import { useToast } from "@chakra-ui/react";
import useErrorStore from "../data/stores/errorStore.js";
import { Controller, useForm } from "react-hook-form";
import useCategoryStore from "../data/stores/categoriesStore.js";

const Login = () => {
  const [modal, setModal] = useState("");
  const { saveAssetCategory, saveLiabilityCategory } = useCategoryStore();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
    },
  });
  const toast = useToast();
  const navigate = useNavigate(),
    { login } = useAuthStore(),
    { addError } = useErrorStore(),
    onSuccess = (data) => {
      console.log(data.data);
      storage?.saveRegEmail(data?.data?.result?.data?.user);
      if (data?.data?.result?.data?.user?.email_verified_at === null) {
        return setModal("verify");
      }
      toast({
        title: "Success",
        description: data?.data?.message.toUpperCase(),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      login(data?.data?.result);
      storage?.saveCredentials(
        data?.data?.result?.data?.token,
        data?.data?.result?.data?.user?.id
      );
      saveAssetCategory();
      saveLiabilityCategory();
    },
    onError = (data) => {
      addError(data?.response?.data);
    },
    { mutate: loginUser, isLoading } = useLogin(onSuccess, onError),
    handleSubmitForm = (formData: ILoginData) => {
      loginUser(formData);
    };
  return (
    <div className="w-full h-screen grid grid-cols-2">
      <div className="h-full w-full flex justify-center items-center bg-white">
        <div className="w-96 shadow-xl h border border-ji-blue-100 rounded-lg p-6">
          <h1 className="text-2xl font-bold text-plain">Welcome Back</h1>
          <h4 className="text-base font-medium text-plain">
            Enter your credientials to continue
          </h4>
          <form
            action=""
            onSubmit={handleSubmit(handleSubmitForm)}
            className="mt-6"
          >
            <div className="space-y-4">
              <div>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field: { value, onChange, name } }) => (
                    <NewTextInput
                      icon={"ic:outline-email"}
                      label={"Email"}
                      error={!!errors?.email}
                      onChange={onChange}
                      value={value}
                      name={name}
                      placeholder={"Enter your email"}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-[#dc2626] mt-2 text-xs">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                      message:
                        "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                    },
                  }}
                  render={({ field: { value, onChange, name } }) => (
                    <NewTextInput
                      icon={"mdi:password-outline"}
                      label={"Password"}
                      onChange={onChange}
                      error={!!errors?.password}
                      name={name}
                      type="password"
                      value={value}
                      placeholder={"Enter your password"}
                    />
                  )}
                />
                {errors.password && (
                  <p className="text-[#dc2626] mt-2 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <p className="text-sm font-medium text-ji-blue-100 text-center">
                Don't have an account?{" "}
                <span
                  onClick={() => navigate("/register")}
                  className="font-semibold cursor-pointer"
                >
                  Sign Up
                </span>
              </p>
            </div>
            <div className="mt-5 flex justify-center">
              <MainBtn text="Sign in" bg="#1D3557" loading={isLoading} />
            </div>
          </form>
        </div>
      </div>
      <div className="background-container w-full h-full"></div>
      {modal === "verify" && <VerifyModal handleClose={() => setModal("")} />}
    </div>
  );
};

export default Login;
