import React, { useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import { AddHeader } from "../../components/app/mainheader";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { fetchSingleAsset } from "../../data/apis/functions/main";
import { Icon } from "@iconify/react";
import {
  useDeleteAsset,
  useMarkAssetAsSold,
} from "../../data/apis/mutations/main";
import { onError } from "../../utils/response";
import MainBtn from "../../components/buttons/main";
import ModalContainer from "../../components/modals/modalcontainer";
import { Spinner, useToast } from "@chakra-ui/react";

const AssetDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { data, isLoading, refetch } = useQuery(
      ["single-asset", state],
      () => fetchSingleAsset(state),
      {}
    ),
    [modal, setModal] = useState(""),
    onSuccess = (data) => {
      toast({
        title: "Success",
        description: data?.data?.message || "Request successful",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setModal("");
      navigate(-1);
    },
    onDeleteSuccess = () => {
      toast({
        title: "Success",
        description: data?.data?.message || "Request successful",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetch();
    },
    { mutate: markAsPaid, isLoading: markLoading } = useMarkAssetAsSold(
      onDeleteSuccess,
      onError
    );
  const Arr = [
      {
        title: "value",
        icon: "rivet-icons:money",
        value: data?.data?.result?.data?.data?.value_in_usd ?? 0,
        bg: "#007AFF",
      },
      {
        title: "Status",
        icon: "fluent:status-12-filled",
        value: data?.data?.result?.data?.data?.ownership_status ?? "",
        bg: "#4CAF50",
      },
    ],
    details = [
      { title: "name", value: data?.data?.result?.data?.data.name },
      {
        title: "description",
        value: data?.data?.result?.data?.data.description ?? "N/A",
      },
      {
        title: "currency",
        value: data?.data?.result?.data?.data?.currency,
      },
      {
        title: "value",
        value: data?.data?.result?.data?.data?.in_word,
      },
      {
        title: "category",
        value: data?.data?.result?.data?.data?.category,
      },
    ],
    { mutate: deleteAsset, isLoading: deleteLoading } = useDeleteAsset(
      onSuccess,
      onError
    );
  const actionBtns = [
    {
      title: "Mark as sold",
      bg: "#F17700",
      shadow: "0px -4px 6.4px 0px #00000040 inset",
      diabled:
        data?.data?.result?.data?.data?.ownership_status === "owned"
          ? false
          : true,
      loading: markLoading ? true : false,
      action: () => markAsPaid(state),
    },
    {
      title: "Edit",
      bg: "#002724",
      shadow: "0px -4px 6.4px 0px #00000040 inset",
      diabled: true,
      action: () =>
        navigate("/assets/edit-asset", {
          state: data?.data?.result?.data?.data,
        }),
    },
    {
      title: "Delete",
      bg: "#E34033",
      shadow: "0px -4px 6.4px 0px #00000040 inset",
      diabled: false,
      action: () => setModal("delete"),
    },
  ];
  return (
    <div>
      <MainContainer>
        <div>
          <AddHeader page={"Asset Details"} />
          <div className="mt-10 grid grid-cols-2 gap-6">
            {Arr?.map((it, i) => (
              <div
                key={i}
                className="h-24 rounded-xl bg-white shadow-sm flex justify-between items-center px-5"
              >
                <div className="size-12 rounded-full shadow-md flex justify-center items-center">
                  <Icon
                    color={it?.bg}
                    style={{
                      fontSize: "24px",
                    }}
                    icon={it?.icon}
                  />
                </div>
                <div>
                  <h5 className="text-xs text-right font-medium text-ji-blue-100 uppercase">
                    {it?.title}
                  </h5>
                  <h1
                    className={`text-sm mt-2 uppercase text-right font-bold ${
                      parseFloat(
                        it?.value && it?.value.replace(/[^0-9.-]+/g, "")
                      ) < 0
                        ? "text-red-500 animate-pulse"
                        : "text-ji-blue-100"
                    } `}
                  >
                    {isLoading ? <Spinner size={"sm"} /> : it?.value}
                  </h1>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 space-y-4">
            {details?.map((d, i) => (
              <div key={i} className="flex gap-6">
                <div className="w-28">
                  <h6 className="text-base capitalize font-semibold text-ji-blue-100">
                    {d?.title}:
                  </h6>
                </div>
                <div className="">
                  <h6 className="text-base font-bold text-ji-blue-100 capitalize">
                    {isLoading ? <Spinner size={"sm"} /> : d?.value}
                  </h6>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8 flex gap-8 items-center">
            {actionBtns?.map((ac) => (
              <button
                onClick={ac?.action}
                style={{
                  background: ac?.bg,
                  boxShadow: ac?.shadow,
                }}
                disabled={ac?.diabled}
                className="w-40 h-10 disabled:opacity-40 disabled:cursor-not-allowed text-white text-sm font-medium rounded-full"
              >
                {ac?.loading ? <Spinner size={"sm"} /> : `${ac?.title}`}
              </button>
            ))}
          </div>
        </div>
      </MainContainer>
      {modal === "delete" && (
        <ModalContainer handleClose={() => setModal("")}>
          <div>
            <h3 className="text-base font-medium text-ji-blue-100">
              Delete Asset
            </h3>
            <div className="mt-6 h-72 w-full flex justify-center items-center">
              <div>
                <h2 className="text-xl text-center px-3 font-normal text-ji-blue-100">
                  Are you sure you want to delete asset{" "}
                  <span className="font-bold italic first-letter:uppercase">
                    <q>{data?.data?.result?.data?.data?.code}</q>
                  </span>
                </h2>
                <div className="mt-8 flex justify-center gap-4">
                  <MainBtn
                    text="Cancel"
                    loading={false}
                    onClick={() => setModal("")}
                  />
                  <MainBtn
                    text="Proceed"
                    loading={deleteLoading}
                    bg="#A52A2A"
                    onClick={() => deleteAsset(state)}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalContainer>
      )}
    </div>
  );
};

export default AssetDetails;
