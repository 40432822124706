export const bankType = [
    { label: 'Savings', value: 'savings' },
    { label: 'Current', value: 'current' },
    { label: 'Fixed', value: 'fixed' },
]
export const relationshipOptions = [
    { label: "Father", value: "father" },
    { label: "Mother", value: "mother" },
    { label: "Brother", value: "brother" },
    { label: "Sister", value: "sister" },
    { label: "Son", value: "son" },
    { label: "Daughter", value: "daughter" },
    { label: "Husband", value: "husband" },
    { label: "Wife", value: "wife" },
    { label: "Uncle", value: "uncle" },
    { label: "Aunt", value: "aunt" },
    { label: "Nephew", value: "nephew" },
    { label: "Niece", value: "niece" },
    { label: "Cousin", value: "cousin" },
    { label: "Grandfather", value: "grandfather" },
    { label: "Grandmother", value: "grandmother" },
    { label: "Grandson", value: "grandson" },
    { label: "Granddaughter", value: "granddaughter" },
    { label: "Friend", value: "friend" },
    { label: "Guardian", value: "guardian" },
    { label: "Other", value: "other" },
  ];