import { Icon } from "@iconify/react";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import useAuthStore from "../../data/stores/authstore";
import { AiOutlineLeft, AiOutlineLogout } from "react-icons/ai";

const MainHeader = () => {
  const { user, logout } = useAuthStore();
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const desiredSegment = pathnameSegments[1];
  const formattedSegment = desiredSegment.replace(/-/g, " ");
  return (
    <div>
      <div className="bg-white rounded-full h-16 w-full flex items-center justify-between px-6">
        <h2 className="text-xl capitalize font-bold text-ji-blue-100">
          {formattedSegment}
        </h2>
        <div className="flex gap-6 items-center">
          <div className="flex gap-3 items-center">
            <Icon
              color={"#1D3557"}
              style={{
                fontSize: "36px",
              }}
              icon={"icon-park-solid:avatar"}
            />
            <strong className="text-base font-medium text-ji-blue-100">
              {user?.first_name} {user?.last_name}
            </strong>
          </div>
          <AiOutlineLogout onClick={logout} />
        </div>
      </div>
    </div>
  );
};

export const AddHeader = ({ page }) => {
  const { user, logout } = useAuthStore();
  const location = useLocation();
  const navigate = useNavigate();
  const pathnameSegments = location.pathname.split("/");
  const desiredSegment = pathnameSegments[1];
  const formattedSegment = desiredSegment.replace(/-/g, " ");
  return (
    <div>
      <div className="bg-white rounded-full h-16 w-full flex items-center justify-between px-6">
        <div className="flex gap-4 items-center">
          <AiOutlineLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
            size={18}
          />
          <h2 className="text-xl capitalize font-bold text-ji-blue-100">
            {formattedSegment} ~ {page}
          </h2>
        </div>
        <div className="flex gap-6 items-center">
          <div className="flex gap-3 items-center">
            <Icon
              color={"#1D3557"}
              style={{
                fontSize: "36px",
              }}
              icon={"icon-park-solid:avatar"}
            />
            <strong className="text-base font-medium text-ji-blue-100">
              {user?.first_name} {user?.last_name}
            </strong>
          </div>
          <AiOutlineLogout onClick={logout} />
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
