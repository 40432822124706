import React, { useEffect, useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import { AddHeader } from "../../components/app/mainheader";
import { fetchLiabilityCategories } from "../../data/apis/functions/main";
import { Toast } from "../../utils/toast";
import { ILiabilityDTO } from "../../utils/types";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import {
  CustomSelect,
  NumberInput,
  RichTextEditor,
  TextInput2,
} from "../../components/inputs/main";
import { currencies } from "../../utils/currency";
import MainBtn from "../../components/buttons/main";
import { useAddLiability } from "../../data/apis/mutations/main";

const AddLiability = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let fetch = async () => {
      Toast.showLoading();
      const res = await fetchLiabilityCategories();
      const data = res.data.result.data.data;
      const selectOptions = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(selectOptions);
      Toast.close();
    };
    fetch();
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState<ILiabilityDTO>({
      name: "",
      category_id: "",
      amount: 0,
      currency: "",
      description: "",
      due_date: "",
    }),
    textChange = (e) => {
      let { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    onSuccess = (data) => {
      console.log(data.data);
      Toast.fire({
        icon: "success",
        text: data?.data?.message.toUpperCase(),
      });
      navigate(-1);
    },
    onError = (data) => {
      console.log(data);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text:
          data.response?.data?.message || data?.data?.message || data?.message,
      });
    },
    { mutate: addLiability, isLoading } = useAddLiability(onSuccess, onError),
    handleSubmit = () => {
      addLiability(data);
    };
  return (
    <div>
      <MainContainer>
        <div>
          <AddHeader page={"Add Liability"} />
          <div className="mt-12 md:w-1/2">
            <h5 className="text-xl text-ji-blue-100 font-semibold">
              Add New Liability
            </h5>
            <form
              action=""
              onSubmit={(e) => e?.preventDefault()}
              className="mt-6"
            >
              <div className="space-y-4">
                <TextInput2
                  label={"Name of Asset"}
                  value={data?.name}
                  onChange={textChange}
                  placeholder={"Enter asset name"}
                  name={"name"}
                />
                {/* <CustomDatePicker
                  date={data?.due_date}
                  setDate={console.log("here")}
                  label={"Due date"}
                /> */}
                <CustomSelect
                  placeholder={"Choose a category"}
                  label={"Category"}
                  options={categories}
                  onChange={(e) => {
                    setData({
                      ...data,
                      category_id: e?.value,
                    });
                  }}
                />
                <CustomSelect
                  placeholder={"Choose a currency"}
                  label={"Currency"}
                  options={currencies}
                  onChange={(e) =>
                    setData({
                      ...data,
                      currency: e?.value,
                    })
                  }
                />
                <NumberInput
                  label={"Value"}
                  value={data?.amount}
                  onChange={textChange}
                  placeholder={"Enter asset value"}
                  name={"value"}
                />
                <RichTextEditor
                  value={data?.description}
                  label={"Description"}
                  setState={(e) => setData({ ...data, description: e })}
                />
              </div>
              <div className="mt-8">
                <MainBtn
                  text="Submit"
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default AddLiability;
