import React from "react";
import ReactPaginate from "react-paginate";

const MainPaginate = ({ handlePageClick, meta }) => {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={Math.ceil(meta.total / meta.per_page)}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        className={`flex gap-4 mb-5 mt-2`}
        pageClassName={`rounded-full px-2 ${"text-deepBlackBg"}`}
        activeClassName={`bg-ji-blue-100 size-7 flex justify-center items-center text-white`}
        previousClassName={`${"text-[rgba(0,0,0,0.6)]"} capitalize font-bold`}
        nextClassName={`${"text-[rgba(0,0,0,0.6)]"} capitalize font-bold`}
      />
    </div>
  );
};

export default MainPaginate;
