import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { IVerifyData } from "../../utils/types";
import MainBtn from "../buttons/main";
import storage from "../../utils/storage";
import {
  useVerifyCode,
  useVerifyPasswordChange,
} from "../../data/apis/mutations/main";
import { Toast } from "../../utils/toast";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import {
  useToast,
  PinInput,
  PinInputField,
  HStack,
  FormControl,
} from "@chakra-ui/react";
import useErrorStore from "../../data/stores/errorStore";
import { Controller, useForm } from "react-hook-form";

const VerifyModal = ({ handleClose }) => {
  const {
      control,
      handleSubmit: submit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: storage?.getRegEmail(),
        otp: "",
      },
    }),
    { addError } = useErrorStore(),
    navigate = useNavigate(),
    onSuccess = (data) => {
      console.log(data.data);
      Toast.fire({
        icon: "success",
        text: data?.data?.message.toUpperCase(),
      });
      handleClose();
      navigate("login");
    },
    onError = (data) => {
      addError(data?.response?.data?.message);
    },
    { mutate: verifyCode, isLoading } = useVerifyCode(onSuccess, onError),
    handleSubmit = (data: IVerifyData) => {
      if (!data?.otp)
        return Swal.fire({
          icon: "error",
          title: "An error has occurred",
          text: "OTP is required",
        });
      verifyCode(data);
    };

  return (
    <div>
      <div className="fixed inset-0 z-[1000] w-full flex justify-center items-center min-h-screen bg-ji-blue-100 bg-opacity-50 backdrop-blur-sm">
        <div
          className={`w-full max-w-md  bg-white backdrop-blur-lg rounded-xl shadow-xl transition-all ease-in-out min-h-3/4 overflow-y-scroll duration-700 noScroll p-4`}
        >
          <form onSubmit={submit(handleSubmit)} className="p-4">
            <h3 className="text-base font-medium text-center text-plain">
              Please enter your verification code
            </h3>
            <div className="flex mt-6 justify-center mt-4'">
              <div>
                <Controller
                  name="otp"
                  control={control}
                  rules={{
                    required: "OTP is required",
                    validate: (value) => {
                      return value.length === 6 || "OTP must be 6 digits";
                    },
                  }}
                  render={({ field: { value, onChange, name } }) => (
                    <FormControl isInvalid={!!errors.otp}>
                      <HStack>
                        <PinInput
                          value={value}
                          onChange={onChange}
                          id={name}
                          otp
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                    </FormControl>
                  )}
                />
              </div>
              {/* <OTPInput
                value={data?.otp}
                onChange={(otp) => setData({ ...data, otp: otp })}
                numInputs={6}
                renderSeparator={<span>-</span>}
                inputStyle={`otp-code__input`}
                //   isDisabled={loading}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
              /> */}
            </div>

            <div className="mt-5 flex justify-center">
              <MainBtn bg="#1D3557" loading={isLoading} text="Submit" />
            </div>
            <CountdownTimer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyModal;

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  useEffect(() => {
    // Function to format time left

    // Countdown function
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdown);
          setIsResendEnabled(true); // Enable resend button when countdown is up
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(countdown);
  }, []);

  const handleResendCode = () => {
    // Your resend code logic here
    console.log("Resend code");
    // Reset the timer or take appropriate action
  };

  return (
    <div className="countdown-container">
      <p className="timer-text">
        {timeLeft > 0 ? `Time left: ${formatTime(timeLeft)}` : "Time is up!"}
      </p>
      <button
        onClick={handleResendCode}
        disabled={!isResendEnabled}
        className="resend-button"
      >
        {isResendEnabled ? "Resend Code" : "Please wait..."}
      </button>
    </div>
  );
};

export const VerifyCodeModal = ({ handleClose, new_password }) => {
  const toast = useToast();
  const { addError } = useErrorStore();
  const [data, setData] = useState({
      otp: "",
      new_password: new_password,
    }),
    navigate = useNavigate(),
    onSuccess = (data) => {
      toast({
        title: "Success",
        description: data?.data?.message.toUpperCase(),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      handleClose();
      navigate("/profile");
    },
    onError = (data) => {
      addError(data?.response?.data?.message);
    },
    { mutate: verifyCode, isLoading } = useVerifyPasswordChange(
      onSuccess,
      onError
    ),
    handleSubmit = () => {
      if (!data?.otp)
        return Swal.fire({
          icon: "error",
          title: "An error has occurred",
          text: "OTP is required",
        });
      verifyCode(data);
    };

  return (
    <div>
      <div className="fixed inset-0 z-[1000] w-full flex justify-center items-center min-h-screen bg-ji-blue-100 bg-opacity-50 backdrop-blur-sm">
        <div
          className={`w-full max-w-md  bg-white backdrop-blur-lg rounded-xl shadow-xl transition-all ease-in-out min-h-3/4 overflow-y-scroll duration-700 noScroll p-4`}
        >
          <div className="p-4">
            <h3 className="text-base font-medium text-center text-plain">
              Please enter your verification code
            </h3>
            <div className="flex mt-6 justify-center mt4'">
              <OTPInput
                value={data?.otp}
                onChange={(otp) => setData({ ...data, otp: otp })}
                numInputs={6}
                renderSeparator={<span>-</span>}
                inputStyle={`otp-code__input`}
                //   isDisabled={loading}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <div className="mt-5 flex justify-center">
              <MainBtn
                bg="#1D3557"
                loading={isLoading}
                text="Submit"
                onClick={handleSubmit}
              />
            </div>
            <CountdownTimer />
          </div>
        </div>
      </div>
    </div>
  );
};
