const ModalContainer = ({ handleClose, children }) => {
  return (
    <div>
      <div
        onClick={(e) => e.target === e.currentTarget && handleClose()}
        className="fixed inset-0 z-[1000] w-full flex justify-center items-center min-h-screen bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <div
          className={`w-full max-w-md  bg-white backdrop-blur-lg rounded-xl shadow-xl transition-all ease-in-out min-h-3/4 overflow-y-scroll duration-700 noScroll p-4`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
