export const currencies = [
    { label: "Nigerian Naira", value: "NGN" },
    { label: "United States Dollar", value: "USD" },
    { label: "Euro", value: "EUR" },
    { label: "Japanese Yen", value: "JPY" },
    { label: "British Pound Sterling", value: "GBP" },
    { label: "Australian Dollar", value: "AUD" },
    { label: "Canadian Dollar", value: "CAD" },
    { label: "Swiss Franc", value: "CHF" },
    { label: "Chinese Yuan", value: "CNY" },
    { label: "Swedish Krona", value: "SEK" },
    { label: "New Zealand Dollar", value: "NZD" },
    { label: "Mexican Peso", value: "MXN" },
    { label: "Singapore Dollar", value: "SGD" },
    { label: "Hong Kong Dollar", value: "HKD" },
    { label: "Norwegian Krone", value: "NOK" },
    { label: "South Korean Won", value: "KRW" },
    { label: "Turkish Lira", value: "TRY" },
    { label: "Russian Ruble", value: "RUB" },
    { label: "Indian Rupee", value: "INR" },
    { label: "Brazilian Real", value: "BRL" },
    { label: "South African Rand", value: "ZAR" },
    { label: "Philippine Peso", value: "PHP" },
    { label: "Czech Koruna", value: "CZK" },
    { label: "Indonesian Rupiah", value: "IDR" },
    { label: "Malaysian Ringgit", value: "MYR" },
    { label: "Hungarian Forint", value: "HUF" },
    { label: "Icelandic Krona", value: "ISK" },
    { label: "Croatian Kuna", value: "HRK" },
    { label: "Bulgarian Lev", value: "BGN" },
    { label: "Romanian Leu", value: "RON" },
    { label: "Danish Krone", value: "DKK" },
    { label: "Thai Baht", value: "THB" },
    { label: "Polish Zloty", value: "PLN" },
    { label: "Israeli New Shekel", value: "ILS" },
    { label: "Chilean Peso", value: "CLP" },
    { label: "Saudi Riyal", value: "SAR" },
    { label: "Emirati Dirham", value: "AED" },
    { label: "Argentine Peso", value: "ARS" },
    { label: "Colombian Peso", value: "COP" },
    { label: "Egyptian Pound", value: "EGP" },
    { label: "Vietnamese Dong", value: "VND" },
    { label: "Bangladeshi Taka", value: "BDT" },
    { label: "Pakistani Rupee", value: "PKR" },
    { label: "Kuwaiti Dinar", value: "KWD" },
    { label: "Qatari Rial", value: "QAR" },
    { label: "Omani Rial", value: "OMR" },
    { label: "Ghanaian Cedi", value: "GHS" },
    { label: "Kenyan Shilling", value: "KES" },
    { label: "Tanzanian Shilling", value: "TZS" },
    { label: "Ugandan Shilling", value: "UGX" },
    { label: "Mauritian Rupee", value: "MUR" },
    { label: "Sri Lankan Rupee", value: "LKR" },
    { label: "Moroccan Dirham", value: "MAD" },
    { label: "Tunisian Dinar", value: "TND" },
    { label: "Algerian Dinar", value: "DZD" },
    { label: "Jordanian Dinar", value: "JOD" },
    { label: "Lebanese Pound", value: "LBP" },
    { label: "Syrian Pound", value: "SYP" },
    { label: "Yemeni Rial", value: "YER" },
    { label: "Bahraini Dinar", value: "BHD" },
    { label: "Sudanese Pound", value: "SDG" },
    { label: "Libyan Dinar", value: "LYD" },
    { label: "Somali Shilling", value: "SOS" },
    { label: "Ethiopian Birr", value: "ETB" },
    { label: "Zambian Kwacha", value: "ZMW" },
    { label: "Botswana Pula", value: "BWP" },
    { label: "Malawi Kwacha", value: "MWK" },
    { label: "Mozambican Metical", value: "MZN" },
    { label: "Zimbabwean Dollar", value: "ZWL" }
];
