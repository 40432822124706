import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { ClipLoader } from "react-spinners";
import Empty from "../../assets/images/empty.png";
import Error from "../../assets/images/error.png";

const LiabilitiesTable = ({ data, error, isLoading }) => {
  const headers = ["code", "name", "value", "category", "date", "action"];
  return (
    <div>
      <table className="table table-auto w-full px-6">
        <thead className=" shadow-md rounded-xl gap-5 h-16 bg-ji-blue-100 text-white font-medium">
          {headers?.map((header, i) => (
            <th className={`capitalize text-left ${i === 0 && "pl-6"}`}>
              {header}
            </th>
          ))}
        </thead>

        {error && (
          <tbody>
            <tr>
              <td colSpan={headers?.length} className="h-64">
                <div className="flex justify-center items-center h-full">
                  <div className="text-center">
                    <img src={Error} alt="No data" className="mx-auto h-44" />
                    <h6 className="text-xl font-semibold text-ji-blue-100">
                      An error occured
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={headers?.length} className="h-64">
                <div className="flex justify-center items-center h-full">
                  <div className="text-center">
                    <img src={Empty} alt="No data" className="mx-auto h-44" />
                    <h6 className="text-xl font-semibold text-ji-blue-100">
                      Nothing to show
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="space-y-4 divide-y-2">
            {isLoading && (
              <div className="w-full h-14 flex justify-center items-center">
                <ClipLoader size={18} color="#354367" />
              </div>
            )}
            {!isLoading &&
              data?.length > 0 &&
              data?.map((it) => (
                <tr className="mt-3 text-left h-14 px-5 shadow-sm rounded-lg bg-gray-50 text-ji-blue-100 font-medium">
                  <td className="pl-6">{it?.code}</td>
                  <td className="truncate">{it?.name}</td>
                  <td>{it?.value_in_usd}</td>
                  <td className="capitalize">{it?.category}</td>
                  <td>{moment(`${it?.created_at}`).format("DD, MMMM YYYY")}</td>
                  <td>
                    <div className="flex items-center gap-4">
                      <Icon
                        color={"#008000"}
                        className="cursor-pointer"
                        style={{
                          fontSize: "20px",
                        }}
                        icon={"lucide:view"}
                      />

                      <Icon
                        color={"#FF0000"}
                        className="cursor-pointer"
                        style={{
                          fontSize: "20px",
                        }}
                        icon={"mingcute:delete-line"}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default LiabilitiesTable;
