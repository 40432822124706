import Swal from "sweetalert2";
import { Toast } from "./toast";

export const onSuccess = (data) => {
  console.log(data.data);
  Toast.fire({
    icon: "success",
    text: data?.data?.message.toUpperCase(),
  });
};
export const onError = (data) => {
  console.log(data);
  Swal.fire({
    icon: "error",
    title: "An error occurred",
    text: data.response?.data?.message || data?.data?.message || data?.message,
  });
};
