import axios from "axios";
import { IAssetData, ILiabilityDTO, ILoginData, INextOfKin, IRegisterData, IVerifyData } from "../../../utils/types";
import { useMutation } from "react-query";
import storage from "../../../utils/storage";

const register = async (data: IRegisterData) => {
    const res = await axios.post('/api/v1/auth/register', { ...data })
    return res
}
const verifyCode = async (data: IVerifyData) => {
    const res = await axios.post('/api/v1/auth/verify-code', { ...data })
    return res
}

const login = async (data: ILoginData) => {
    const res = await axios.post('/api/v1/auth/login', { ...data })
    return res
}
const addNextOfKin = async (data: INextOfKin) => {
    const res = await axios.post('/api/v1/next-of-kin/add', { ...data }, storage?.getConfigWithBearer())
    return res
}

const addAsset = async (data: IAssetData) => {
    const res = await axios?.post('/api/v1/asset/add', { ...data }, storage?.getConfigWithBearer())
    return res
}

export const addLiability = async (data: ILiabilityDTO) => {
    const res = await axios.post('/api/v1/liability/add', storage?.getConfigWithBearer())
    return res
}

export const deleteAsset = async (id: string) => {
    const res = await axios.delete(`/api/v1/asset/delete/${id}`, storage?.getConfigWithBearer())
    return res
}

export const markAssetAsSold = async (id: string) => {
    const res = await axios.post(`/api/v1/asset/mark-as-sold/${id}`, storage?.getConfigWithBearer())
    return res
}

const changePassword = async (data) => {
    const res = await axios.post('/api/v1/auth/change-password', { ...data }, storage?.getConfigWithBearer())
    return res
}

const verifyChangePassword = async (data) => {
    const res = await axios.post('/api/v1/auth/change-password/verify', { ...data }, storage?.getConfigWithBearer())
    return res
}



export const useVerifyPasswordChange = (onSuccess: any, onError: any) => {
    return useMutation(verifyChangePassword, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useRegister = (onSuccess: any, onError: any) => {
    return useMutation(register, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useChangePassword = (onSuccess: any, onError: any) => {
    return useMutation(changePassword, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useVerifyCode = (onSuccess: any, onError: any) => {
    return useMutation(verifyCode, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useLogin = (onSuccess: any, onError: any) => {
    return useMutation(login, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useAddNextOfKin = (onSuccess: any, onError: any) => {
    return useMutation(addNextOfKin, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useAddAsset = (onSuccess: any, onError: any) => {
    return useMutation(addAsset, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useAddLiability = (onSuccess: any, onError: any) => {
    return useMutation(addLiability, {
        onSuccess: onSuccess,
        onError: onError
    })
}

export const useDeleteAsset = (onSuccess: any, onError: any) => {
    return useMutation(deleteAsset, {
        onSuccess: onSuccess,
        onError: onError
    })
}
export const useMarkAssetAsSold = (onSuccess: any, onError: any) => {
    return useMutation(markAssetAsSold, {
        onSuccess: onSuccess,
        onError: onError
    })
}
