import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

export const TOKEN = "JIVANNA_USER_TOKEN";

const AuthStore = (set) => ({
  token: localStorage.getItem(TOKEN) || null,
  user: null,
  isAuth: false,
  loading: false,
  isLoggedIn: false,

  login: (payload) => {
    localStorage.setItem(TOKEN, payload?.data?.token);
    set(
      {
        user: payload?.data?.user || payload,
        token: payload?.data?.token,
        isLoggedIn: true,
        isAuth: true,
      },
      false,
      "login"
    );
  },
  saveAssetCategory: (payload) => {
    set({
      assetCategory: payload?.data?.data,
    });
  },
  saveLiabilityCategory: (payload) => {
    set({
      liabilityCategory: payload?.data?.data,
    });
  },
  logout: () => {
    localStorage.removeItem(TOKEN);
    localStorage.clear();
    set(
      {
        isAuth: false,
        user: null,
        token: null,
        role: null,
      },
      false,
      "logout"
    );
  },
});

const useAuthStore = create(
  devtools(
    persist(AuthStore, {
      name: "jivana-auth",
    })
  )
);

export default useAuthStore;
