import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  fetchCategories,
  fetchLiabilityCategories,
} from "../apis/functions/main";

const CategoryStore = (set) => ({
  assetCategory: null,
  liabilityCategory: null,
  saveAssetCategory: async () => {
    const res = await fetchCategories();
    set({
      assetCategory: res.data.result.data.data,
    });
  },
  saveLiabilityCategory: async () => {
    const res = await fetchLiabilityCategories();
    set({
      liabilityCategory: res.data.result.data.data,
    });
  },
});

const useCategoryStore = create(
  devtools(
    persist(CategoryStore, {
      name: "jivana-category",
    })
  )
);

export default useCategoryStore;
