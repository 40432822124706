/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import MainHeader from "../../components/app/mainheader";
import {
  fetchNextOfKin,
  fetchProfileDetails,
  useGetFunc,
} from "../../data/apis/functions/main";
import { INextOfKin } from "../../utils/types";
import { CustomSelect, NewTextInput } from "../../components/inputs/main.jsx";
import MainBtn from "../../components/buttons/main";
import Swal from "sweetalert2";
import {
  useAddNextOfKin,
  useChangePassword,
} from "../../data/apis/mutations/main";
import ReactDOMServer from "react-dom/server";
import { relationshipOptions } from "../../utils/selectoptions";
import { Icon } from "@iconify/react";
import Man from "../../assets/images/man.jpg";
import { useToast } from "@chakra-ui/react";
import Spinners from "../../utils/spinner";
import { VerifyCodeModal } from "../../components/modals/verifymodal";

const Profile = () => {
  const [tab, setTab] = useState("profile"),
    tabs = [
      { name: "profile", icon: "iconamoon:profile-fill" },
      { name: "next of Kin", icon: "mdi:family" },
      { name: "password", icon: "solar:password-bold" },
    ];
  return (
    <div>
      <MainContainer>
        <div>
          <MainHeader />
          <div className="w-full mt-8 flex gap-10">
            <div className="w-[20%] bg-white p-5 rounded-md">
              <div className="space-y-4">
                {tabs?.map((t) => (
                  <div
                    onClick={() => setTab(t?.name)}
                    className={`flex px-2 cursor-pointer h-12 gap-3 rounded-lg items-center ${
                      tab === t?.name
                        ? "bg-ji-blue-100 bg-opacity-65"
                        : "bg-gray-100"
                    } border-[0.5px] border-ji-blue-100 border-opacity-40`}
                  >
                    <div
                      className={`size-8 bg-white flex justify-center items-center rounded-full shadow-sm`}
                    >
                      <Icon
                        icon={t?.icon}
                        style={{
                          fontSize: "22px",
                        }}
                        color="#354367"
                      />
                    </div>
                    <h6
                      className={`text-sm capitalize font-normal ${
                        tab === t?.name
                          ? "text-white font-medium"
                          : "text-ji-blue-100"
                      }`}
                    >
                      {t?.name}
                    </h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[80%] bg-white rounded-lg p-6">
              {tab === "profile" && <ProfileSection />}
              {tab === "next of Kin" && <NextOfKinSection />}
              {tab === "password" && <PasswordSection />}
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

const ProfileSection = () => {
  const { data, isLoading } = useGetFunc(
    fetchProfileDetails,
    "profile-details"
  );
  // const [edit, setEdit] = useState(false);
  const profile = data?.data?.result?.data[0];
  // const [profileData, setprofileData] = useState<IUserProfile>({
  //   first_name: data?.data?.result?.data[0]?.first_name ?? "",
  //   last_name: data?.data?.result?.data[0]?.last_name ?? "",
  //   dob: data?.data?.result?.data[0]?.dob ?? "",
  //   address: data?.data?.result?.data[0]?.address ?? "",
  //   nationality: data?.data?.result?.data[0]?.nationality ?? "",
  //   state_of_origin: data?.data?.result?.data[0]?.state_of_origin ?? "",
  //   occupation: data?.data?.result?.data[0]?.occupation ?? "",
  //   employment_status: data?.data?.result?.data[0]?.employment_status ?? "",
  //   selfie: data?.data?.result?.data[0]?.selfie ?? "",
  //   profile_picture: data?.data?.result?.data[0]?.profile_picture ?? "",
  //   industry_worked_in: data?.data?.result?.data[0]?.industry_worked_in ?? "",
  //   school: data?.data?.result?.data[0]?.school ?? "",
  //   course_of_study: data?.data?.result?.data[0]?.course_of_study ?? "",
  //   estimated_monthly_income:
  //     data?.data?.result?.data[0]?.estimated_monthly_income ?? 0,
  //   gender: data?.data?.result?.data[0]?.gender ?? "undisclosed",
  // });
  const profileArr = [
    {
      name: "first_name",
      label: "First Name",
      icon: "icon-park-outline:edit-name",
      value: profile?.first_name || "",
    },
    {
      name: "last_name",
      label: "Last Name",
      icon: "icon-park-outline:edit-name",
      value: profile?.last_name || "",
    },
    {
      name: "dob",
      label: "Date of Birth",
      icon: "icon-park-outline:calendar",
      value: profile?.dob || "",
    },
    {
      name: "nationality",
      label: "Nationality",
      type: "select",
      icon: "icon-park-outline:flag",
      value: profile?.nationality || "",
    },
    {
      name: "state_of_origin",
      label: "State of Origin",
      type: "select",
      icon: "mdi:address-marker",
      value: profile?.state_of_origin || "",
    },
    {
      name: "occupation",
      label: "Occupation",
      type: "select",
      icon: "icon-park-outline:briefcase",
      value: profile?.occupation || "",
    },
    {
      name: "employment_status",
      label: "Employment Status",
      type: "select",
      icon: "icon-park-outline:id-card",
      value: profile?.employment_status || "",
    },
    {
      name: "industry_worked_in",
      label: "Industry Worked In",
      icon: "streamline:industry-innovation-and-infrastructure",
      value: profile?.industry_worked_in || "",
    },
    {
      name: "school",
      label: "School",
      icon: "icon-park-outline:school",
      value: profile?.school || "",
    },
    {
      name: "course_of_study",
      label: "Course of Study",
      icon: "icon-park-outline:book",
      value: profile?.course_of_study || "",
    },
    {
      name: "estimated_monthly_income",
      label: "Estimated Monthly Income",
      icon: "icon-park-outline:income",
      value: profile?.estimated_monthly_income || "",
    },
    {
      name: "gender",
      label: "Gender",
      icon: "ph:gender-female-fill",
      type: "select",
      value: profile?.gender || "",
    },
  ];
  return (
    <div>
      <div className="w-full border-b border-b-gray-300 pb-2">
        <h2 className="text-xl font-bold text-ji-blue-100">Personal Profile</h2>
      </div>
      <div className="mt-3 flex gap-4 items-center">
        <div className="size-28 rounded-full">
          <img
            src={Man}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div>
          <h6 className="text-base text-light text-gray-500">
            We only support .JPG, .JPEG, or .PNG file.
          </h6>
          <button className="flex mt-2 gap-2 rounded-lg items-center px-4 h-10 bg-ji-blue-100">
            <Icon
              icon={"ep:upload-filled"}
              color="white"
              style={{
                fontSize: "20px",
              }}
            />
            <span className="text-base text-white font-medium">Upload</span>
          </button>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-2 gap-6 gap-y-4">
        {profileArr?.map((pro, i) => (
          <div key={i} className="">
            <h4 className="text-sm font-normal text-ji-blue-100">
              {pro?.label}
            </h4>
            <div className="flex gap-3 items-center">
              <Icon
                icon={pro?.icon}
                style={{
                  fontSize: "24px",
                }}
                color="#354367"
              />
              <strong className="text-base font-medium text-ji-blue-100">
                {isLoading ? <Spinners /> : pro?.value || "N/A"}
              </strong>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const NextOfKinSection = () => {
  const [edit, setEdit] = useState(false);
  const toast = useToast();
  const {
      data: nextOfKin,
      isLoading,
      refetch,
    } = useGetFunc(fetchNextOfKin, "next-of-kin"),
    nok = nextOfKin?.data?.result?.data?.data,
    [nextOfKinData, setNextOfKinData] = useState<INextOfKin>({
      first_name: "",
      last_name: "",
      relationship: "",
      phone_number: "",
      email: "",
    }),
    textChange = (e) => {
      let { name, value } = e.target;
      setNextOfKinData({ ...nextOfKinData, [name]: value });
    },
    onSuccess = (data) => {
      console.log(data.data);
      toast({
        title: "Success",
        description: data?.data?.message.toUpperCase(),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setEdit(false);
      refetch();
    },
    onError = (data) => {
      console.log(data);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text:
          data.response?.data?.message || data?.data?.message || data?.message,
      });
    },
    { mutate: addNextOfKin, isLoading: NokLoading } = useAddNextOfKin(
      onSuccess,
      onError
    ),
    handleNok = () => {
      let err = [];
      if (!nextOfKinData?.first_name) err.push("First name is required");
      if (!nextOfKinData?.last_name) err.push("Last name is required");
      if (!nextOfKinData?.email) err.push("Email is required");
      if (!nextOfKinData?.phone_number) err.push("Phone number is required");
      if (!nextOfKinData?.relationship) err.push("Relationship is required");

      if (err.length > 0) {
        const errorHtml = ReactDOMServer.renderToStaticMarkup(
          <>
            {err.map((error, i) => (
              <p key={i} className="font-medium text-sm">
                <span className="">{err.length > 1 && <>{i + 1}.</>}</span>
                {error}
              </p>
            ))}
          </>
        );
        return Swal.fire({
          title: "An error occurred",
          html: errorHtml,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
      addNextOfKin(nextOfKinData);
    },
    nokArr = [
      {
        name: "first_name",
        label: "First Name",
        icon: "icon-park-outline:edit-name",
        value: nok?.first_name,
      },
      {
        name: "last_name",
        label: "Last Name",
        icon: "icon-park-outline:edit-name",
        value: nok?.last_name,
      },
      {
        name: "email",
        label: "Email",
        icon: "ic:outline-email",
        value: nok?.email,
      },
      {
        name: "phone_number",
        label: "Phone Number",
        icon: "mingcute:phone-line",
        value: nok?.phone_number,
      },
      {
        name: "relationship",
        label: "Relationship",
        icon: "icon-park-twotone:family",
        type: "select",
        options: relationshipOptions,
        value: nok?.relationship,
      },
    ];
  useEffect(() => {
    if (nextOfKin?.data?.result?.data?.data) {
      const data = nextOfKin?.data?.result?.data?.data;
      setNextOfKinData({
        ...nextOfKinData,
        first_name: data.first_name,
        last_name: data.last_name,
        relationship: data?.relationship,
        email: data?.email,
        phone_number: data?.phone_number,
      });
    }
  }, [nextOfKin]);
  return (
    <div>
      <div className="w-full flex justify-between items-center border-b border-b-gray-300 pb-2">
        <h2 className="text-xl font-bold text-ji-blue-100">Next of Kin</h2>
        {edit ? (
          <MainBtn loading={NokLoading} onClick={handleNok} text="Update" />
        ) : (
          <MainBtn loading={false} text="Edit" onClick={() => setEdit(true)} />
        )}
      </div>
      {!edit && (
        <div className="mt-6 grid grid-cols-2 gap-6 gap-y-4">
          {nokArr?.map((pro, i) => (
            <div key={i} className="">
              <h4 className="text-sm font-normal text-ji-blue-100">
                {pro?.label}
              </h4>
              <div className="flex gap-3 items-center">
                <Icon
                  icon={pro?.icon}
                  style={{
                    fontSize: "24px",
                  }}
                  color="#354367"
                />
                <strong className="text-base first-letter:uppercase font-medium text-ji-blue-100">
                  {isLoading ? <Spinners /> : pro?.value || "N/A"}
                </strong>
              </div>
            </div>
          ))}
        </div>
      )}
      {edit && (
        <div className="mt-6 grid grid-cols-2 gap-6">
          {nokArr?.map((nok) => (
            <div>
              {nok?.type === "select" ? (
                <div>
                  <CustomSelect
                    placeholder={"Select a relationship"}
                    options={nok?.options}
                    onChange={(e) =>
                      setNextOfKinData({
                        ...nextOfKinData,
                        relationship: e?.value,
                      })
                    }
                    label={nok?.label}
                  />
                  <small className="text-xs text-ji-blue-100 font-medium">
                    Current option: {nextOfKinData?.relationship}
                  </small>
                </div>
              ) : (
                <NewTextInput
                  icon={nok?.icon}
                  name={nok?.name}
                  label={nok?.label}
                  value={nextOfKinData[nok?.name]}
                  onChange={textChange}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PasswordSection = () => {
  const [data, setData] = useState({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    }),
    [modal, setModal] = useState(""),
    toast = useToast(),
    textChange = (e) => {
      let { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    onSuccess = (data) => {
      console.log(data.data);
      toast({
        title: "Success",
        description: data?.data?.message.toUpperCase(),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setModal("verify");
    },
    onError = (data) => {
      console.log(data);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text:
          data.response?.data?.message || data?.data?.message || data?.message,
      });
    },
    { mutate: changePassword, isLoading } = useChangePassword(
      onSuccess,
      onError
    ),
    handleSubmit = () => {
      let err = [];
      if (!data?.current_password) err.push("Current password is required");
      if (!data?.new_password) err.push("New password is required");
      if (!data?.new_password_confirmation)
        err.push("New password confirmation is required");
      if (data?.new_password !== data?.new_password_confirmation)
        err.push("New password and confirmation must be the same");
      if (err.length > 0) {
        const errorHtml = ReactDOMServer.renderToStaticMarkup(
          <>
            {err.map((error, i) => (
              <p key={i} className="font-medium text-sm">
                <span className="">{err.length > 1 && <>{i + 1}.</>}</span>
                {error}
              </p>
            ))}
          </>
        );
        return Swal.fire({
          title: "An error occurred",
          html: errorHtml,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
      changePassword(data);
    };

  return (
    <div>
      <div className="w-full flex justify-between items-center border-b border-b-gray-300 pb-2">
        <h2 className="text-xl font-bold text-ji-blue-100">Password</h2>
      </div>
      <div className="mt-6 w-1/2">
        <form action="" onSubmit={(e) => e?.preventDefault()}>
          <div className="space-y-4">
            <NewTextInput
              value={data?.current_password}
              onChange={textChange}
              type="password"
              icon={"mdi:password-outline"}
              name={"current_password"}
              label={"Current password"}
            />
            <NewTextInput
              value={data?.new_password}
              onChange={textChange}
              type="password"
              icon={"mdi:password-outline"}
              name={"new_password"}
              label={"New password"}
            />
            <NewTextInput
              value={data?.new_password_confirmation}
              onChange={textChange}
              type="password"
              icon={"mdi:password-outline"}
              name={"new_password_confirmation"}
              label={"Confirm new password"}
            />
          </div>
          <div className="mt-6">
            <MainBtn text="Submit" onClick={handleSubmit} loading={isLoading} />
          </div>
        </form>
      </div>
      {modal === "verify" && (
        <VerifyCodeModal
          handleClose={() => setModal("")}
          new_password={data?.new_password}
        />
      )}
    </div>
  );
};

// const Junks = () => {
//   return (
//     <div>
//       <div className="h-36 bg-ji-blue-100 mt-8 rounded-xl w-full flex justify-center items-center">
//         <div>
//           <h4 className="text-white font-bold text-center">
//             {profile?.first_name} {profile?.last_name}
//           </h4>
//         </div>
//       </div>
//       <div className="size-24 mx-auto -mt-10 bg-white rounded-full border-2 border-white">
//         <img
//           src={profile?.profile_picture || Avatar}
//           alt=""
//           className="size-full rounded-full"
//         />
//       </div>
//       <div className="mt-16 grid grid-cols-2 gap-8">
//         <div>
//           <h4 className="text-xl font-medium text-ji-blue-100">
//             Profile Details
//           </h4>
//           <form action="" className="mt-4">
//             <div className="space-y-4"></div>
//           </form>
//         </div>
//         <div>
//           <h4 className="text-xl font-medium text-ji-blue-100">
//             Next of kin Details
//           </h4>
//           <form
//             onSubmit={(e) => e?.preventDefault()}
//             action=""
//             className="mt-4"
//           >
//             <div className="space-y-4">
//               <TextInput2
//                 label={"First name"}
//                 value={nextOfKinData?.first_name}
//                 onChange={textChange}
//                 placeholder={"Enter first name"}
//                 name={"first_name"}
//               />
//               <TextInput2
//                 label={"Last name"}
//                 value={nextOfKinData?.last_name}
//                 onChange={textChange}
//                 placeholder={"Enter first name"}
//                 name={"last_name"}
//               />

//               <TextInput2
//                 label={"Email Address"}
//                 value={nextOfKinData?.email}
//                 onChange={textChange}
//                 placeholder={"Enter email address"}
//                 name={"email"}
//               />
//               <PhoneInput
//                 name={"phone_number"}
//                 label={"Phone number"}
//                 onChange={textChange}
//                 placeholder={"1234567890"}
//                 value={nextOfKinData?.phone_number}
//               />
//             </div>
//             <div className="flex justify-end mt-7">
//               <MainBtn loading={NokLoading} onClick={handleNok} text="Update" />
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

{
  /* <div className="mt-6 grid grid-cols-2 gap-5">
<NewTextInput
  name={"first_name"}
  label={"First Name"}
  icon={"icon-park-outline:edit-name"}
  onChange={textChange}
  value={profileData?.first_name || ""}
/>

<NewTextInput
  name={"last_name"}
  label={"Last Name"}
  icon={"icon-park-outline:edit-name"}
  onChange={textChange}
  value={profileData?.last_name || ""}
/>

<NewTextInput
  name={"dob"}
  label={"Date of Birth"}
  icon={"icon-park-outline:calendar"}
  onChange={textChange}
  value={profileData?.dob || ""}
/>

<NewTextInput
  name={"address"}
  label={"Address"}
  icon={"mdi:address-marker"}
  onChange={textChange}
  value={profileData?.address || ""}
/>

<NewTextInput
  name={"nationality"}
  label={"Nationality"}
  icon={"icon-park-outline:flag"}
  onChange={textChange}
  value={profileData?.nationality || ""}
/>

<NewTextInput
  name={"state_of_origin"}
  label={"State of Origin"}
  icon={"icon-park-outline:map-flag"}
  onChange={textChange}
  value={profileData?.state_of_origin || ""}
/>

<NewTextInput
  name={"occupation"}
  label={"Occupation"}
  icon={"icon-park-outline:briefcase"}
  onChange={textChange}
  value={profileData?.occupation || ""}
/>

<NewTextInput
  name={"employment_status"}
  label={"Employment Status"}
  icon={"icon-park-outline:id-card"}
  onChange={textChange}
  value={profileData?.employment_status || ""}
/>

<NewTextInput
  name={"selfie"}
  label={"Selfie URL"}
  icon={"icon-park-outline:camera"}
  onChange={textChange}
  value={profileData?.selfie || ""}
/>

<NewTextInput
  name={"profile_picture"}
  label={"Profile Picture URL"}
  icon={"icon-park-outline:picture"}
  onChange={textChange}
  value={profileData?.profile_picture || ""}
/>

<NewTextInput
  name={"industry_worked_in"}
  label={"Industry Worked In"}
  icon={"streamline:industry-innovation-and-infrastructure"}
  onChange={textChange}
  value={profileData?.industry_worked_in || ""}
/>

<NewTextInput
  name={"school"}
  label={"School"}
  icon={"icon-park-outline:school"}
  onChange={textChange}
  value={profileData?.school || ""}
/>

<NewTextInput
  name={"course_of_study"}
  label={"Course of Study"}
  icon={"icon-park-outline:book"}
  onChange={textChange}
  value={profileData?.course_of_study || ""}
/>

<NewTextInput
  name={"estimated_monthly_income"}
  label={"Estimated Monthly Income"}
  icon={"icon-park-outline:money"}
  onChange={textChange}
  value={profileData?.estimated_monthly_income || ""}
/>

<NewTextInput
  name={"gender"}
  label={"Gender"}
  icon={"icon-park-outline:gender"}
  onChange={textChange}
  value={profileData?.gender || ""}
/>
</div> */
}
export default Profile;
