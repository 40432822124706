import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Router";
import DataProvider from "./data/Context";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// import { SetAuthToken, SetDefaultHeaders } from "./data/Config";
// import "react-toastify/dist/ReactToastify.css";
import { SetAuthToken, SetDefaultHeaders } from "./data/Config";
import "../src/App.css";
import useAuthStore from "./data/stores/authstore";
import { useIdleTimer } from "react-idle-timer";
import { ChakraProvider } from "@chakra-ui/react";

SetDefaultHeaders();
if (localStorage.getItem("JIVANNA_USER_TOKEN")) {
  SetAuthToken(localStorage.getItem("token"));
  console.log(localStorage?.getItem("token"));
}

function App() {

  const queryClient = new QueryClient();
  const { logout } = useAuthStore(),
    handleLogOut = () => {
      logout();
    },
    { getLastActiveTime } = useIdleTimer({
      timeout: 1000 * 60 * 10,
      onIdle: handleLogOut,
      debounce: 500,
    });
  console.log(getLastActiveTime);
  return (
    <div className="App">
      <DataProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
            <Router>
              <Routers />
            </Router>
          </ChakraProvider>
          <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
        </QueryClientProvider>
      </DataProvider>
    </div>
  );
}

export default App;
