import React, { useMemo, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import Upload from "../../assets/icons/export.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { enGB } from "date-fns/locale";
// import { DatePicker, DatePickerCalendar } from "react-nice-dates";
import { Icon } from "@iconify/react";
//...

const TextInput = ({ name, label, value, onChange, placeholder }) => {
  return (
    <div className="">
      <div>
        {" "}
        <small className="text-sm font-semibold text-ji-blue-100">
          {label}
        </small>
      </div>
      <input
        type="text"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className="h-10 pl-4 shadow-sm placeholder:font-light bg-gray-200 placeholder:text-sm w-full border-opacity-45 rounded-lg text-sm font-medium text-plain"
      />
    </div>
  );
};

export const NumberInput = ({ name, label, value, onChange, placeholder }) => {
  return (
    <div className="">
      <div>
        <small className="text-sm font-normal text-ji-blue-100">{label}</small>
      </div>
      <input
        type="text"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className="h-10 pl-4 shadow-sm placeholder:font-light bg-gray-white border border-ji-blue-100 placeholder:text-sm w-full border-opacity-45 rounded-lg text-sm font-medium text-plain"
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }}
      />
    </div>
  );
};

export const PhoneInput = ({ name, onChange, placeholder, value, label }) => {
  return (
    <div>
      <div>
        <small className="text-sm font-semibold text-ji-blue-100">
          {label}
        </small>
      </div>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-sm font-medium text-plain">+234</span>
        </div>
        <input
          type="text"
          maxLength={10}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          className="h-10 pl-14 shadow-sm bg-gray-100 placeholder:font-light placeholder:text-sm w-full border-opacity-45 rounded-lg text-sm font-medium text-plain"
        />
      </div>
    </div>
  );
};

export const PasswordInput = ({
  name,
  label,
  value,
  onChange,
  placeholder,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div>
        <small className="text-sm font-semibold text-ji-blue-100">
          {label}
        </small>
      </div>
      <div className="h-10 w-full relative">
        <input
          type={show ? "text" : "password"}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          className="h-full pl-4 shadow-sm placeholder:font-light placeholder:text-sm bg-gray-200 w-full border-opacity-45 rounded-lg text-sm font-medium text-plain"
        />
        <div
          onClick={() => setShow(!show)}
          className="absolute top-3 right-4 cursor-pointer"
        >
          {show ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>
    </div>
  );
};

export const TextInput2 = ({ name, label, value, onChange, placeholder }) => {
  return (
    <div className="">
      <div>
        {" "}
        <small className="text-sm font-normal text-ji-blue-100">{label}</small>
      </div>
      <input
        type="text"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className="h-10 pl-4 shadow-sm placeholder:font-light bg-gray-white border border-ji-blue-100 placeholder:text-sm w-full border-opacity-45 rounded-lg text-sm font-medium text-plain"
      />
    </div>
  );
};

export const CustomSelect = ({ onChange, options, placeholder, label }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "12px, 17px, 12px, 17px",
      height: "40px",
      fontSize: "14px",
      width: "100%",
      color: "#1D3557",
      fontWeight: "500",
      border: "0.5px solid #00000033",
      borderRadius: "8px",
      textTransform: "capitalize",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0",
    }),
    option: (provided) => ({
      ...provided,
      padding: "15px",
      textTransform: "capitalize",
    }),
  };
  return (
    <div className="">
      <div>
        <small className="text-sm font-normal text-ji-blue-100">{label}</small>
      </div>
      <div className="tw-mt-2">
        <Select
          onChange={onChange}
          styles={customStyles}
          options={options}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export const RichTextEditor = ({ label, value, setState }) => {
  const quillRef = useRef(null);

  // const onSuccess = (data) => {
  //     const imageUrl = data?.data?.data[0]?.url;
  //     const quill = quillRef.current.getEditor();
  //     const range = quill.getSelection();
  //     quill.insertEmbed(range.index, "image", imageUrl);
  //   },
  //   onError = () => {};
  // const { mutate: addFiles, isLoading } = useAddFiles(onSuccess, onError);
  // useEffect(() => {
  //   if (isLoading) {
  //     Toast.showLoading();
  //   } else {
  //     Toast.close();
  //   }
  // }, [isLoading]);
  // const imageHandler = useCallback(() => {
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "image/*");
  //   input.click();

  //   input.onchange = () => {
  //     const file = input?.files && input?.files[0];
  //     if (!file) return;
  //     const data = { files: file };
  //     addFiles(data);
  //   };
  // }, [addFiles]);
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        // handlers: {
        //   image: imageHandler,
        // },
      },
    }),
    []
  );
  return (
    <div>
      <div>
        <small className="text-sm font-normal text-ji-blue-100">{label}</small>
      </div>
      <div className="tw-w-full tw-mt-2">
        <ReactQuill
          style={{
            backgroundColor: "white",
          }}
          theme="snow"
          value={value}
          ref={quillRef}
          formats={formats}
          modules={modules}
          onChange={(e) => setState(e)}
        />
      </div>
    </div>
  );
};

export const ImageInput = ({ name, label, bg, type, onChange, state }) => {
  const ref = useRef();
  const handleClick = () => {
    ref.current?.click();
  };

  return (
    <div>
      <div>
        {" "}
        <small className="tw-text-base tw-font-medium satoshi tw-text-secondary">
          {label}
        </small>
      </div>
      <div
        onClick={handleClick}
        style={{
          backgroundImage: type === "image" ? `url(${bg})` : null,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="tw-h-28 tw-mt-2 tw-cursor-pointer tw-bg-white tw-border-[#7B87944D] tw-border tw-w-full tw-flex tw-justify-center tw-items-center tw-rounded-md"
      >
        <div className="">
          {state ? (
            <>
              {name === "video" ? (
                <video
                  src={state?.url || URL.createObjectURL(state)}
                  className="tw-mx-auto tw-max-h-24"
                  muted
                  autoPlay
                  controls
                />
              ) : (
                <img
                  src={state?.url || URL.createObjectURL(state)}
                  alt=""
                  className="tw-mx-auto tw-max-h-24"
                />
              )}
            </>
          ) : (
            <>
              <img src={Upload} alt="" className="tw-mx-auto" />
              <small className="tw-text-xs tw-text-[#275A7F] tw-font-light satoshi tw-text-center">
                Click here to upload or Drop files here
              </small>
            </>
          )}
          <input
            type="file"
            name={name}
            onChange={onChange}
            ref={ref}
            accept={".mp4, .mkv, .pdf, .png, .jpg, .png"}
            id=""
            className="tw-hidden"
          />
        </div>
      </div>
    </div>
  );
};

export const CustomDatePicker = ({ label, date, setDate }) => {
  return (
    <div>
      <small className="tw-text-base tw-font-medium satoshi tw-text-secondary">
        {label}
      </small>
      <div className="mt-2">
        {/* <DatePicker date={date} onDateChange={setDate} locale={enGB}>
          {({ inputProps, focused }) => (
            <input
              className={"input" + (focused ? " -focused" : "")}
              {...inputProps}
            />
          )}
        </DatePicker> */}
      </div>
    </div>
  );
};

export const NewTextInput = ({
  onChange,
  error = false,
  icon,
  label,
  name,
  placeholder = "",
  value,
  type = "text", // Default type is text
}) => {
  const [show, setShow] = useState(false); // For toggling password visibility

  return (
    <div>
      <div className="form-group">
        <label
          htmlFor={name}
          className="text-sm font-semibold text-ji-blue-100"
        >
          {label}
        </label>
        <div className="h-10 w-full bg-gray-100 rounded-lg relative">
          <div className="absolute top-2 left-4">
            {/* Display the icon */}
            <Icon
              color="354367"
              style={{
                fontSize: "22px",
              }}
              icon={icon}
            />
          </div>
          <input
            type={type === "password" && show ? "text" : type} // Correctly handle the toggle for passwords
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            className={`h-full pl-12 placeholder:font-light bg-transparent placeholder:text-sm w-full rounded-lg text-sm font-medium text-plain ${
              error && "border border-[#dc2626]"
            }`}
          />
          {type === "password" && (
            <div
              onClick={() => setShow(!show)} // Toggle visibility
              className="absolute top-3 right-4 cursor-pointer"
            >
              {show ? <FaEyeSlash /> : <FaEye />}{" "}
              {/* Show the eye icon for toggle */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextInput;
