import React, { useState } from "react";
import VerifyModal from "../components/modals/verifymodal";
import MainBtn from "../components/buttons/main";
import { useToast } from "@chakra-ui/react";
import { IRegisterData } from "../utils/types";
import { useNavigate } from "react-router";
import storage from "../utils/storage";
import { useRegister } from "../data/apis/mutations/main";
import { NewTextInput, PhoneInput } from "../components/inputs/main";
import useErrorStore from "../data/stores/errorStore";
import { Controller, useForm } from "react-hook-form";

const Register = () => {
  const [modal, setModal] = useState("");
  const toast = useToast(),
    { addError } = useErrorStore(),
    {
      control,
      handleSubmit: submit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: "",
        password: "",
        password_confirmation: "",
        first_name: "",
        last_name: "",
        phone_number: "",
      },
    }),
    navigate = useNavigate(),
    onSuccess = (data) => {
      console.log(data.data);
      toast({
        title: "Success",
        description: data?.data?.message.toUpperCase(),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      storage?.saveRegEmail(data?.data?.result?.data?.user);
      setModal("verify");
    },
    onError = (data) => {
      addError(data?.response?.data);
    },
    { mutate: register, isLoading } = useRegister(onSuccess, onError),
    handleSubmit = (data: IRegisterData) => {
      register(data);
    };
  return (
    <div>
      <div className="w-full h-screen grid grid-cols-2">
        <div className="h-full w-full py-16 flex justify-center items-center bg-white">
          <div className="w-96 shadow-xl h border border-ji-blue-100 rounded-lg p-6">
            <h1 className="text-2xl font-bold text-plain">Welcome!</h1>
            <h4 className="text-base mt-2 font-medium text-plain">
              Enter your credientials to gain access to our services
            </h4>
            <form action="" onSubmit={submit(handleSubmit)} className="mt-6">
              <div className="space-y-4">
                <div>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{
                      required: "First name is required",
                    }}
                    render={({ field: { value, name, onChange } }) => (
                      <NewTextInput
                        icon={"icon-park-outline:edit-name"}
                        label={"First Name"}
                        onChange={onChange}
                        value={value}
                        name={name}
                        error={!!errors?.first_name}
                        placeholder={"Enter your first name"}
                      />
                    )}
                  />
                  {errors.first_name && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.first_name.message}
                    </p>
                  )}
                </div>

                <div>
                  <Controller
                    name="last_name"
                    control={control}
                    rules={{
                      required: "Last name is required",
                    }}
                    render={({ field: { value, name, onChange } }) => (
                      <NewTextInput
                        icon={"icon-park-outline:edit-name"}
                        label={"Last Name"}
                        onChange={onChange}
                        value={value}
                        name={name}
                        error={!!errors?.last_name}
                        placeholder={"Enter your last name"}
                      />
                    )}
                  />
                  {errors.last_name && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.last_name.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    }}
                    render={({ field: { value, onChange, name } }) => (
                      <NewTextInput
                        icon={"ic:outline-email"}
                        label={"Email"}
                        onChange={onChange}
                        value={value}
                        error={!!errors?.email}
                        name={name}
                        placeholder={"Enter your email"}
                      />
                    )}
                  />
                  {errors.email && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: "Phone number is required",
                      validate: (value) => {
                        // Add any additional validation logic here
                        return (
                          value.length >= 10 ||
                          "Phone number must be at least 10 digits"
                        );
                      },
                    }}
                    render={({ field: { value, name, onChange } }) => (
                      <PhoneInput
                        name={name}
                        label={"Phone number"}
                        onChange={onChange}
                        placeholder={"1234567890"}
                        value={value}
                      />
                    )}
                  />
                  {errors.phone_number && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.phone_number.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required",
                      pattern: {
                        value:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                        message:
                          "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                      },
                    }}
                    render={({ field: { value, onChange, name } }) => (
                      <NewTextInput
                        icon={"mdi:password-outline"}
                        label={"Password"}
                        onChange={onChange}
                        name={name}
                        type="password"
                        value={value}
                        error={!!errors?.password}
                        placeholder={"Enter your password"}
                      />
                    )}
                  />
                  {errors.password && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div>
                  <Controller
                    name="password_confirmation"
                    control={control}
                    rules={{
                      required: "Password Confirmation is required",
                      pattern: {
                        value:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                        message:
                          "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                      },
                    }}
                    render={({ field: { value, onChange, name } }) => (
                      <NewTextInput
                        icon={"mdi:password-outline"}
                        label={"Password"}
                        onChange={onChange}
                        error={!!errors?.password_confirmation}
                        name={name}
                        type="password"
                        value={value}
                        placeholder={"Enter your password"}
                      />
                    )}
                  />
                  {errors.password_confirmation && (
                    <p className="text-[#dc2626] mt-2 text-xs">
                      {errors.password_confirmation.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <p className="text-sm font-medium text-ji-blue-100 text-center">
                  Already have an account?{" "}
                  <span
                    onClick={() => navigate("/login")}
                    className="font-semibold cursor-pointer"
                  >
                    Sign In
                  </span>
                </p>
              </div>
              <div className="mt-5 flex justify-center">
                <MainBtn text="Sign up" bg="#1D3557" loading={isLoading} />
              </div>
            </form>
          </div>
        </div>
        <div className="background-container w-full h-full"></div>
        {modal === "verify" && <VerifyModal handleClose={() => setModal("")} />}
      </div>
    </div>
  );
};

export default Register;
