import { createElement } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuthStore from "./data/stores/authstore";

const GeneratePage = (pageName: string, folder: string) => {
  const component = () => require(`./${folder}/${pageName}`).default;
  let navigate = useNavigate();
  try {
    return createElement(component());
  } catch (e) {
    console.log(e);
    navigate("/");
  }
};

const PageRender = () => {
  const { page, id, step } = useParams();
  const { isAuth } = useAuthStore();
  const escape2 = ["add-asset", "add-liability", "edit-asset"];

  let pageName = "";
  if (step) {
    pageName = `${page}/${id}/${"[id]"}`;
  } else if (id) {
    if (
      (page === "assets" && escape2.includes(id)) ||
      (page === "liabilities" && escape2?.includes(id))
    ) {
      pageName = `${page}/${id}`;
    } else {
      pageName = `${page}/${"[id]"}`;
    }
  } else {
    pageName = `${page}`;
  }

  return GeneratePage(pageName, isAuth ? "pages" : "screens");
};

export default PageRender;
