import React, { useEffect, useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import MainHeader from "../../components/app/mainheader";
import MainBtn from "../../components/buttons/main";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import {
  assetStat,
  fetchAsset,
  useGetFunc,
} from "../../data/apis/functions/main";
import AssetsTable from "../../components/tables/assetstable";
import { CustomSelect } from "../../components/inputs/main";
import { useQuery } from "react-query";
import MainPaginate from "../../components/app/mainpaginate";
import { Spinner } from "@chakra-ui/react";
import useCategoryStore from "../../data/stores/categoriesStore";

const Assets = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const { assetCategory } = useCategoryStore();
  const [meta, setMeta] = useState({
    per_page: 1,
    total: 1,
  });
  const { data, isLoading, refetch } = useQuery(
    ["assets", page, category],
    () => fetchAsset(page, category),

    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setMeta({
          per_page: data.data?.meta.per_page,
          total: data.data?.meta.total,
        });
      },
      onError: () => {
        setError(true);
      },
    }
  );
  const { data: stat, isLoading: statLoading } = useGetFunc(assetStat, "stat");
  const handlePageClick = (event: any) => {
    const currentPage = event.selected + 1;
    setPage(currentPage);
  };
  const Arr = [
    {
      title: "Total Assets",
      value: stat?.data?.result?.data?.count,
      bg: "#007AFF",
      icon: "mdi:property-tag",
    },
    {
      title: "Total value (Sold)",
      value: stat?.data?.result?.data?.total_sold,
      bg: "#FF6347",
      icon: "mdi:property-tag",
    },
    {
      title: "Total value (Owned)",
      value: stat?.data?.result?.data?.total_owned,
      bg: "#4CAF50",
      icon: "mdi:property-tag",
    },
  ];
  useEffect(() => {
    if (assetCategory) {
      const selectOptions = assetCategory.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(selectOptions);
    }
  }, [assetCategory]);
  return (
    <div>
      <MainContainer>
        <div>
          <MainHeader />
          <div className="flex justify-end mt-6">
            <MainBtn
              text="Add Asset"
              loading={false}
              onClick={() => navigate("/assets/add-asset")}
            />
          </div>

          <div className="mt-8 grid lg:grid-cols-3 md:grid-cols-2 gap-8 w-full">
            {Arr?.map((it) => (
              <div className="h-24 rounded-xl bg-white shadow-sm flex justify-between items-center px-5">
                <div className="size-12 rounded-full shadow-md flex justify-center items-center">
                  <Icon
                    color={it?.bg}
                    style={{
                      fontSize: "24px",
                    }}
                    icon={it?.icon}
                  />
                </div>
                <div>
                  <h5 className="text-xs text-right font-medium text-ji-blue-100 uppercase">
                    {it?.title}
                  </h5>
                  <h1 className="text-2xl mt-2 text-right font-bold text-ji-blue-100">
                    {statLoading ? <Spinner size={"sm"} /> : it?.value}
                  </h1>
                </div>
              </div>
            ))}
          </div>
          <div className="flex mt-10 justify-between items-center">
            <div className="w-1/3">
              <CustomSelect
                label={"Filter"}
                options={categories}
                placeholder={"select a category"}
                onChange={(e) => setCategory(e?.value)}
              />
            </div>
            <MainBtn
              text="Reset"
              loading={false}
              onClick={() => setCategory("")}
            />
          </div>
          <div className="mt-4">
            <AssetsTable
              refetch={refetch}
              error={error}
              isLoading={isLoading}
              data={data?.data?.data}
            />
            <MainPaginate handlePageClick={handlePageClick} meta={meta} />
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Assets;
