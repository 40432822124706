import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import MainApp from "./screens";
import PageRender from "./PageRender";
import Home from "./screens/home";
import useAuthStore from "./data/stores/authstore";
import Index from "./pages";
import Sidebar from "./components/sidebar";
import useErrorStore from "./data/stores/errorStore";
import Swal from "sweetalert2";

const Routers = () => {
  const location = useLocation(),
    { isAuth, logout } = useAuthStore(),
    { errors, clearErrors } = useErrorStore();
  useEffect(() => {
    if (errors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        confirmButtonText: "Try again",
        text: errors[0],
      }).then((result) => {
        if (result?.isConfirmed) {
          if (errors[0]?.includes("authenticated")) {
            logout();
          }
          clearErrors();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <Sidebar>
        <Routes>
          <Route path="/" element={isAuth ? <Index /> : <Home />} />
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/:page/:id/:step" element={<PageRender />} />
        </Routes>
      </Sidebar>
    </div>
  );
};

export default Routers;
