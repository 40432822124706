import React, { useEffect, useState } from "react";
import MainContainer from "../../components/app/maincontainer";
import { AddHeader } from "../../components/app/mainheader";
import { IAssetData } from "../../utils/types";
import { Toast } from "../../utils/toast";
import Swal from "sweetalert2";
import { fetchCategories } from "../../data/apis/functions/main";
import {
  CustomSelect,
  NumberInput,
  RichTextEditor,
  TextInput2,
} from "../../components/inputs/main.jsx";
import ReactDOMServer from "react-dom/server";
import { currencies } from "../../utils/currency";
import { bankType } from "../../utils/selectoptions";
import MainBtn from "../../components/buttons/main";
import { useAddAsset } from "../../data/apis/mutations/main";
import { useNavigate } from "react-router";

const EditAsset = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState<IAssetData>({
      category_id: "",
      currency: "",
      value: 0,
      name: "",
      description: "",
      location: "",
      longitude: undefined,
      latitude: undefined,
      bank_name: "",
      account_number: "",
      account_type: undefined,
    }),
    [catType, setCatType] = useState(""),
    textChange = (e) => {
      let { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    onSuccess = (data) => {
      console.log(data.data);
      Toast.fire({
        icon: "success",
        text: data?.data?.message.toUpperCase(),
      });
      navigate(-1);
    },
    onError = (data) => {
      console.log(data);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text:
          data.response?.data?.message || data?.data?.message || data?.message,
      });
    },
    { mutate: addAsset, isLoading } = useAddAsset(onSuccess, onError),
    handleSubmit = () => {
      let err = [];
      if (!data?.category_id) err?.push("Category is required");
      if (!data?.currency) err?.push("Currency is required");
      if (!data?.value) err?.push("Value is required");
      if (!data?.name) err?.push("Name is required");
      if (err?.length > 0) {
        const errorHtml = ReactDOMServer.renderToStaticMarkup(
          <>
            {err.map((err, i) => (
              <p key={i} className="fw-bold Lexend text-center w-100">
                <span className="fontInherit me-2">
                  {err.length > 1 && <>{i + 1}.</>}
                </span>{" "}
                {err}
              </p>
            ))}
          </>
        );
        return Swal.fire({
          title: "An error occurred",
          html: errorHtml,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
      addAsset(data);
    };
  useEffect(() => {
    let fetch = async () => {
      Toast.showLoading();
      const res = await fetchCategories();
      const data = res.data.result.data.data;
      const selectOptions = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(selectOptions);
      Toast.close();
    };
    fetch();
  }, []);

  return (
    <div>
      <MainContainer>
        <div>
          <AddHeader page={"Add Asset"} />
          <div className="mt-12 md:w-1/2">
            <h5 className="text-xl text-ji-blue-100 font-semibold">
              Add New Asset
            </h5>
            <form
              action=""
              onSubmit={(e) => e?.preventDefault()}
              className="mt-6"
            >
              <div className="space-y-4">
                <TextInput2
                  label={"Name of Asset"}
                  value={data?.name}
                  onChange={textChange}
                  placeholder={"Enter asset name"}
                  name={"name"}
                />
                <CustomSelect
                  placeholder={"Choose a category"}
                  label={"Category"}
                  options={categories}
                  onChange={(e) => {
                    setData({
                      ...data,
                      category_id: e?.value,
                    });
                    setCatType(e?.label);
                  }}
                />
                <CustomSelect
                  placeholder={"Choose a currency"}
                  label={"Currency"}
                  options={currencies}
                  onChange={(e) =>
                    setData({
                      ...data,
                      currency: e?.value,
                    })
                  }
                />
                <NumberInput
                  label={"Value"}
                  value={data?.value}
                  onChange={textChange}
                  placeholder={"Enter asset value"}
                  name={"value"}
                />
                <RichTextEditor
                  value={data?.description}
                  label={"Description"}
                  setState={(e) => setData({ ...data, description: e })}
                />
                {catType === "bank accounts" && (
                  <div className="space-y-4">
                    <TextInput2
                      label={"Bank Name"}
                      value={data?.bank_name}
                      onChange={textChange}
                      placeholder={"Enter bank name"}
                      name={"bank_name"}
                    />
                    <CustomSelect
                      placeholder={"Choose an account type"}
                      label={"Account type"}
                      options={bankType}
                      onChange={(e) =>
                        setData({
                          ...data,
                          account_type: e?.value,
                        })
                      }
                    />
                    <NumberInput
                      label={"Account Number"}
                      value={data?.account_number}
                      onChange={textChange}
                      placeholder={"Enter asset value"}
                      name={"account_number"}
                    />
                  </div>
                )}
              </div>
              <div className="mt-8">
                <MainBtn
                  text="Submit"
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default EditAsset;
