import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import Empty from "../../assets/images/empty.png";
import Error from "../../assets/images/error.png";
import { useDeleteAsset } from "../../data/apis/mutations/main";
import { onError } from "../../utils/response";
import ModalContainer from "../modals/modalcontainer";
import MainBtn from "../buttons/main";
import { Toast } from "../../utils/toast";
import { useNavigate } from "react-router";

const AssetsTable = ({ data, isLoading, refetch, error }) => {
  const headers = ["name", "value", "category", "status", "date", "action"],
    navigate = useNavigate(),
    [id, setId] = useState(null),
    [modal, setModal] = useState(""),
    onDeleteSuccess = () => {
      Toast.fire({
        icon: "success",
        text: "Asset deleted successfully",
      });
      refetch();
    },
    { mutate: deleteAsset, isLoading: deleteLoading } = useDeleteAsset(
      onDeleteSuccess,
      onError
    ),
    handleModal = (id) => {
      setModal("delete");
      setId(id);
    };
  return (
    <div>
      <table className="table table-auto w-full px-6">
        <thead className="shadow-md rounded-xl gap-5 h-16 bg-ji-blue-100 text-white font-medium">
          {headers?.map((header, i) => (
            <th key={i} className={`capitalize text-left ${i === 0 && "pl-6"}`}>
              {header}
            </th>
          ))}
        </thead>
        {error && (
          <tbody>
            <tr>
              <td colSpan={headers?.length} className="h-64">
                <div className="flex justify-center items-center h-full">
                  <div className="text-center">
                    <img src={Error} alt="No data" className="mx-auto h-44" />
                    <h6 className="text-xl font-semibold text-ji-blue-100">
                      An error occured
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={headers?.length} className="h-64">
                <div className="flex justify-center items-center h-full">
                  <div className="text-center">
                    <img src={Empty} alt="No data" className="mx-auto h-44" />
                    <h6 className="text-xl font-semibold text-ji-blue-100">
                      Nothing to show
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="space-y-4 divide-y-2">
            {isLoading && (
              <tr>
                <td colSpan={headers?.length}>
                  <div className="w-full h-14 flex justify-center items-center">
                    <ClipLoader size={18} color="#354367" />
                  </div>
                </td>
              </tr>
            )}
            {!isLoading &&
              data?.length > 0 &&
              data?.map((it, idx) => (
                <tr
                  key={idx}
                  className="mt-3 text-left text-sm  h-14 px-5 shadow-sm rounded-lg bg-gray-50 text-ji-blue-100 font-medium"
                >
                  <td className="pl-6 capitalize">{it?.name}</td>
                  <td className="truncate first-letter:uppercase">
                    {it?.value_in_usd}
                  </td>
                  <td className="capitalize">{it?.category}</td>
                  <td className="flex h-full items-center">
                    <div
                      className={`h-8 flex mt-2 justify-center rounded-full items-center w-20 capitalize text-sm font-bold ${
                        it?.ownership_status === "owned"
                          ? "text-[#4CAF50] bg-[#4caf50] bg-opacity-35"
                          : "text-[#A52A2A] bg-[#a52a2a] bg-opacity-35"
                      }`}
                    >
                      {it?.ownership_status}
                    </div>
                  </td>
                  <td>{moment(`${it?.created_at}`).format("DD, MMMM YYYY")}</td>
                  <td>
                    <div className="flex items-center gap-4">
                      <Icon
                        color={"#008000"}
                        className="cursor-pointer"
                        style={{ fontSize: "20px" }}
                        onClick={() =>
                          navigate(`/assets/${it?.code}`, { state: it?.id })
                        }
                        icon={"lucide:view"}
                      />
                      <Icon
                        color={"#0000FF"}
                        className="cursor-pointer"
                        style={{ fontSize: "20px" }}
                        icon={"lucide:edit"}
                      />
                      <Icon
                        color={"#FF0000"}
                        className="cursor-pointer"
                        style={{ fontSize: "20px" }}
                        icon={"mingcute:delete-line"}
                        onClick={() => handleModal(it)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
      {modal === "delete" && (
        <ModalContainer handleClose={() => setModal("")}>
          <div>
            <h3 className="text-base font-medium text-ji-blue-100">
              Delete Asset
            </h3>
            <div className="mt-6 h-72 w-full flex justify-center items-center">
              <div>
                <h2 className="text-xl text-center px-3 font-normal text-ji-blue-100">
                  Are you sure you want to delete asset{" "}
                  <span className="font-bold italic first-letter:uppercase">
                    <q>{id?.code}</q>
                  </span>
                </h2>
                <div className="mt-8 flex justify-center gap-4">
                  <MainBtn
                    text="Cancel"
                    loading={false}
                    onClick={() => setModal("")}
                  />
                  <MainBtn
                    text="Proceed"
                    loading={deleteLoading}
                    bg="#A52A2A"
                    onClick={() => deleteAsset(id?.id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalContainer>
      )}
    </div>
  );
};

export default AssetsTable;
