let storage = {};

storage.saveCredentials = (token, id) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", id);
};

storage.checkToken = () => {
  return localStorage.getItem("token") ? true : false;
};

storage.checkorgId = () => {
  return localStorage.getItem("orgId") ? true : false;
};

storage.saveOrgId = (val) => {
  localStorage.setItem("orgId", val);
};

storage.getOrgId = () => {
  return localStorage.getItem("orgId");
};

storage.getToken = () => {
  return localStorage.getItem("token");
};

storage.saveNavLink = (val) => {
  localStorage.setItem("recent-link", JSON.stringify(val));
};

storage.saveRegEmail = (user) => {
  localStorage.setItem("regEmail", user?.email);
};

storage.getRegEmail = () => {
  const e = localStorage.getItem("regEmail");
  return e;
};

storage.getNavLink = () => {
  return JSON.parse(localStorage.getItem("recent-link"));
};

storage.saveNavPage = (val) => {
  localStorage.setItem("recent-page", JSON.stringify(val));
};

storage.getNavPage = () => {
  return JSON.parse(localStorage.getItem("recent-page"));
};

storage.checkUserID = () => {
  return localStorage.getItem("userId") ? true : false;
};

storage.getUserID = () => {
  let uid = localStorage.getItem("userId");
  return uid ? uid : "";
};

storage.getConfigWithBearer = () => {
  const config = {
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${storage.getToken()}`,
    },
  };
  return config;
};

storage.getConfigWithBearerForFile = () => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${storage.getToken()}`,
    },
  };
  return config;
};

storage.getConfigWithOrgBearer = (orgId) => {
  const config = {
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${storage.getToken()}`,
      // Organisation: `${orgId ? orgId : storage.getOrgId()}`
      Organisation: `${storage.getOrgId()}`,
    },
  };
  return config;
};

storage.copyCode = (code) => {
  if (code !== "" && code !== undefined && typeof code === "string") {
    navigator.clipboard.writeText(code);
    return true;
  } else {
    return false;
  }
};

export default storage;
