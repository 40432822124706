import React from "react";

const MainContainer = ({ children }) => {
  return (
    <div className="bg-[#e8d1dd]">
      <div className="w-full min-h-screen overflow-visible p-10 bg-blue-50">
        {children}
      </div>
    </div>
  );
};

export default MainContainer;
