import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const ErrorStore = (set) => ({
  errors: [],
  errorText: "",

  getErrorText: (payload) => {
    set({ errorText: payload }, false, "getErrorText");
  },

  addError: (payload) => {
    set(
      (state) => ({
        errors: [...state.errors, payload?.message || payload],
      }),
      false,
      "addError"
    );
  },

  clearErrors: () => {
    console.log("hhh");
    set({ errors: [], errorText: "" }, false, "clearErrors");
  },
});

const useErrorStore = create(
  devtools(
    persist(ErrorStore, {
      name: "error",
    })
  )
);

export default useErrorStore;
