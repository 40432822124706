import axios from "axios"
import { useQuery } from "react-query";
import storage from "../../../utils/storage";

export const getQuotes = async () => {
    const res = await axios.get('https://api.api-ninjas.com/v1/quotes?category=life', {
        headers: {
            'X-Api-Key': process.env.REACT_APP_QUOTES_API_KEY
        }
    });
    return res
}
export const fetchProfileDetails = async () => {
    const res = await axios.get('/api/v1/profile/fetch', storage?.getConfigWithBearer())
    return res
}

export const fetchNextOfKin = async () => {
    const res = await axios.get('/api/v1/next-of-kin/fetch', storage?.getConfigWithBearer())
    return res
}
export const fetchAsset = async (page, category) => {
    const res = await axios.get(`/api/v1/asset/list?page=${page}&category=${category}`, storage?.getConfigWithBearer())
    return res
}

export const fetchCategories = async () => {
    const res = await axios.get('/api/v1/asset/fetch-categories', storage?.getConfigWithBearer())
    return res
}

export const assetStat = async () => {
    const res = await axios.get('/api/v1/asset/stat', storage?.getConfigWithBearer())
    return res
}
export const liabilityStat = async () => {
    const res = await axios.get('/api/v1/liability/stat', storage?.getConfigWithBearer())
    return res
}

export const fetchLiability = async (page: number, category: string) => {
    const res = await axios.get(`api/v1/liability/list?page=${page}&category=${category}`, storage.getConfigWithBearer())
    return res
}
export const fetchLiabilityCategories = async () => {
    const res = await axios.get('/api/v1/liability/categories/list', storage?.getConfigWithBearer())
    return res
}

export const fetchDashboardData = async () => {
    const res = await axios.get('/api/v1/dashboard/data', storage?.getConfigWithBearer())
    return res
}

export const fetchSingleAsset = async (id: string) => {
    const res = await axios.get(`api/v1/asset/list/${id}`, storage.getConfigWithBearer())
    return res
}

export const useGetFunc = (func: any, queryKey: string) => {
    return useQuery({
        queryFn: () => func(),
        queryKey: queryKey,
    })
}